<template>
  <a-modal
    class="dialog-refuse"
    v-model="visible"
    :title="isEdit ? '编辑客户' : '添加客户'"
    width="840px"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    :afterClose="handleAfterClose"
    @ok="handleOk"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 9 }"
      :wrapper-col="{ span: 15 }"
    >
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-model-item label="客户名称" prop="customerName">
            <a-input v-model.trim="form.customerName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="联系方式" prop="linkPhone">
            <a-input v-model.trim="form.linkPhone" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="证件号" prop="idcardCode">
            <a-input v-model.trim="form.idcardCode" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="地址" prop="address">
            <a-input type="textarea" v-model.trim="form.address" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-divider />
        <a-col :span="12">
          <a-form-model-item label="车辆厂牌" prop="brandName">
            <a-input v-model.trim="form.brandName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="车牌号" prop="plateNo">
            <a-input v-model.trim="form.plateNo" placeholder="请输入" @change="form.plateNo = form.plateNo && typeof form.plateNo === 'string' ? form.plateNo.toUpperCase() : ''" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="车架号" prop="frmNo">
            <a-input v-model.trim="form.frmNo" placeholder="请输入" @change="form.frmNo = form.frmNo && typeof form.frmNo === 'string' ? form.frmNo.toUpperCase().slice(0, 17) : ''" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="发动机号" prop="engNo">
            <a-input v-model.trim="form.engNo" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="大保单号" prop="bigPolicyNo">
            <a-input v-model.trim="form.bigPolicyNo" placeholder="请输入" disabled />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="保单号" prop="policyNo">
            <a-input v-model.trim="form.policyNo" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="车辆年限" prop="years">
            <a-input
              type="number"
              v-model="form.years"
              placeholder="请输入"
              addonAfter="年"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="新车/二手车购置价" prop="carPrice">
            <a-input
              type="number"
              v-model="form.carPrice"
              placeholder="请输入"
              addonAfter="万元"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="安装时间" prop="installTime">
            <a-date-picker
              v-model="form.installTime"
              value-format="YYYY-MM-DD [00]:[00]:[00]"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="安装区域" prop="regionCode">
            <a-spin class="custom-spin" :spinning="regionLoading" size="small">
              <a-cascader
                placeholder="请选择省市区"
                v-model="form.regionCode"
                :options="regionOptions"
                @change="regionChange"
              />
            </a-spin>
          </a-form-model-item>
        </a-col>
        <a-col :span="24" style="padding:0">
          <a-col :span="12">
            <a-form-model-item label="安装地址" prop="installAddr">
              <a-input type="textarea" v-model.trim="form.installAddr" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { phoneValidate } from '@/utils/validator'
import { common, customer } from '@/api'
import moment from 'moment'

export default {
  name: 'DialogCustomerEdit',

  props: {
    show: Boolean,
    current: Object
  },

  setup (props, { root, emit }) {
    const visible = computed({
      get: () => props.show,
      set: val => {
        emit('update:show', val)
      }
    })
    const isEdit = computed(() => Boolean(props.current.xindlCustomerId))

    const regionOptions = ref([])
    const regionLoading = ref(false)
    watch(() => visible.value, data => {
      if (data && !regionOptions.length) getRegionTree()
      if (props.current && Object.keys(props.current).length) {
        const { installRegionCode } = props.current
        Object.keys(form.value).forEach(item => {
          form.value[item] = props.current[item]
        })
        if (installRegionCode) {
          form.value.regionCode = [installRegionCode.substr(0, 2), installRegionCode.substr(0, 4), installRegionCode]
        }
      }
    })
    async function getRegionTree () {
      regionLoading.value = true
      const { data, msg, code } = await common.getRegionTree()
      regionLoading.value = false
      if (code === '00000') {
        regionOptions.value = handleTreeData(data)
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }
    function handleTreeData (data) {
      if (!data || !data.length) return
      for (let item of data) {
        item.value = item.regionCode
        item.label = item.regionName
        item.children = handleTreeData(item.children)
      }
      return data
    }
    function regionChange (e, selectedOptions) {
      if (selectedOptions) {
        form.value.installProvince = selectedOptions[0].label
        form.value.installCity = selectedOptions[1].label
        form.value.installCounty = selectedOptions[2].label
        form.value.installRegionCode = selectedOptions[2].regionCode
      } else {
        form.value.installProvince = ''
        form.value.installCity = ''
        form.value.installCounty = ''
        form.value.installRegionCode = ''
      }
    }

    const formRef = ref(null)
    const form = ref({
      customerName: '',
      linkPhone: '',
      idcardCode: '',
      address: '',
      brandName: '',
      plateNo: '',
      frmNo: '',
      engNo: '',
      serialNumber: '',
      installProvince: '',
      installCity: '',
      installCounty: '',
      regionCode: [],
      installRegionCode: '',
      installAddr: '',
      installTime: '',
      validTime: '',
      endTime: '',
      bigPolicyNo: '815152020110106000010',
      policyNo: '',
      years: 1,
      carPrice: '',
      remark: '',
      vehiclePics: ''
    })
    const validNormal = [
      { required: true, message: '请输入' },
      { max: 32, message: '请输入简短一些' }
    ]
    const rules = ref({
      customerName: validNormal,
      linkPhone: [
        { required: true, message: '请输入' },
        { validator: phoneValidate }
      ],
      idcardCode: [
        { required: true, message: '请输入' }
      ],
      address: [
        { required: true, message: '请输入' },
        { max: 128, message: '请输入简短一些' }
      ],
      brandName: validNormal,
      plateNo: [
        { required: true, message: '请输入' },
        { pattern: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/, message: '格式不正确' }
      ],
      frmNo: [
        { required: true, message: '请输入' },
        { pattern: /^[A-Z0-9]{17}$/, message: '格式不正确' }
      ],
      engNo: [
        { required: true, message: '请输入' },
        { pattern: /^[0-9A-Za-z\\-]{1,20}$/, message: '格式不正确' }
      ],
      installAddr: [
        { max: 128, message: '请输入简短一些' }
      ],
      bigPolicyNo: validNormal,
      policyNo: validNormal,
      years: [
        { required: true, message: '请输入' },
        { pattern: /^[1-9]\d*$/, message: '格式不正确' }
      ],
      carPrice: [
        { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式不正确' }
      ]
    })

    watch(() => form.value.installTime, data => {
      const validTime = moment(data).add(1, 'd')
      form.value.validTime = validTime.format('YYYY-MM-DD')
      form.value.endTime = validTime.add(1, 'y').format('YYYY-MM-DD')
    })
    watch(() => form.value.policyNo, data => {
      form.value.serialNumber = data
    })

    function handleAfterClose () {
      formRef.value.resetFields()
    }

    const confirmLoading = ref(false)
    function handleOk () {
      formRef.value.validate(async valid => {
        if (!valid) return
        confirmLoading.value = true
        const param = Object.assign({
          ...form.value,
          ...(isEdit.value ? { xindlCustomerId: props.current.xindlCustomerId } : {})
        })
        if (!param.installTime) {
          const nowDate = moment(new Date())
          param.installTime = nowDate.format('YYYY-MM-DD')
          param.validTime = nowDate.add(1, 'd').format('YYYY-MM-DD')
          param.endTime = nowDate.add(1, 'd').add(1, 'y').format('YYYY-MM-DD')
        }
        const { code, msg } = await customer.updateCustomer(param)
        confirmLoading.value = false
        if (code === '00000') {
          root.$message.success('提交成功')
          visible.value = false
          emit('success', isEdit.value)
        } else {
          root.$message.error(msg || '提交失败，请稍后重试')
        }
      })
    }

    return {
      visible,
      isEdit,
      regionLoading,
      regionOptions,
      regionChange,
      formRef,
      form,
      rules,
      handleAfterClose,
      confirmLoading,
      handleOk
    }
  }
}
</script>

<style lang="less" scoped>
.custom-spin {
  ::v-deep > div > .ant-spin .ant-spin-dot {
    left: initial;
    right: 18px;
  }
}
.upload-box {
  display: flex;
  .example {
    position: relative;
    flex: none;
    width: 160px;
    height: 120px;
    margin-left: 12px;
    .img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}
.custom-upload-single {
  flex: none;
  width: 160px;
  height: 120px;
  ::v-deep .icon-plus {
    font-size: 24px;
  }
}
</style>
