<template>
  <div class="page-box">
    <base-screen>
      <a-form-model
        ref="formRef"
        layout="inline"
        :model="queryRef"
        @submit="handleSearch"
        @submit.native.prevent
      >
        <a-form-model-item label="客户名称" prop="customerName">
          <a-input v-model="queryRef.customerName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="linkPhone">
          <a-input v-model="queryRef.linkPhone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="保单号" prop="policyNo">
          <a-input v-model="queryRef.policyNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="保单类型" prop="policyType">
          <a-select v-model="queryRef.policyType" placeholder="请选择" style="min-width: 120px">
            <a-select-option value="0">机舱线路保护剂</a-select-option>
            <a-select-option value="1">引擎抗磨剂A</a-select-option>
            <a-select-option value="2">引擎抗磨剂X</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="生效时间" prop="validTime">
          <a-range-picker v-model="validTime" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button
        type="primary"
        @click="handleAdd"
      >
        录入
      </a-button>
      <a-button
        :loading="exportLoading"
        @click="handleExport"
      >
        导出
      </a-button>
    </div>
    <base-table
      bordered
      :columns="columns"
      :dataSource="dataRef"
      :pagination="pageRtv"
      :rowKey="record => record.xindlCustomerId"
      :loading="loadingRef || loading"
      :scroll="{x: 1200}"
      @change="handleTableChange"
    >
      <template #drivingPic="record">
        <a href="javascript:;" v-if="record.drivingPic" @click="previewImage = record.drivingPic">
          <base-img :src="record.drivingPic" width="90" height="60" />
        </a>
      </template>
      <template #operation="record">
        <div class="table-operations">
          <a-button type="link" @click="handleEdit(record)">
            编辑
          </a-button>
          <a-popconfirm
            title="确认删除当前客户吗？"
            @confirm="handleDelete(record.xindlCustomerId)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </base-table>
    <dialog-customer-edit
      :show.sync="editShow"
      :current="currentCustomer"
      @success="handleEditSuccess"
    />
    <a-modal
      title="行驶证图片预览"
      :visible="Boolean(previewImage)"
      :footer="null"
      :zIndex="12000"
      @cancel="previewImage = ''"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { customer } from '@/api'
import { useExport } from '@/utils/tools'
import moment from 'moment'
import DialogCustomerEdit from './DialogCustomerEdit'

const columns = [
  {
    title: '客户名称',
    dataIndex: 'customerName'
  }, {
    title: '联系方式',
    dataIndex: 'linkPhone'
  }, {
    title: '车牌号',
    dataIndex: 'plateNo'
  }, {
    title: '保单号',
    dataIndex: 'policyNo'
  }, {
    title: '生效日期',
    dataIndex: 'validTime',
    customRender: x => moment(x).format('YYYY-MM-DD')
  }, {
    title: '截止日期',
    dataIndex: 'endTime',
    customRender: x => moment(x).format('YYYY-MM-DD')
  }, {
    title: '行驶证图片',
    scopedSlots: {
      customRender: 'drivingPic'
    }
  }, {
    title: '操作',
    fixed: 'right',
    scopedSlots: {
      customRender: 'operation'
    }
  }
]
export default {
  name: 'PageCustomer',

  components: {
    DialogCustomerEdit
  },

  setup (props, { root }) {
    const formRef = ref(null)
    const validTime = ref([])
    watch(() => validTime.value, data => {
      if (data.length) {
        queryRef.value.validTime = `${moment(validTime.value[0]).format('YYYY-MM-DD')} 00:00:00`
        queryRef.value.endTime = `${moment(validTime.value[1]).format('YYYY-MM-DD')} 23:59:59`
      } else {
        queryRef.value.validTime = ''
        queryRef.value.endTime = ''
      }
    })
    const queryRef = ref({
      customerName: '',
      linkPhone: '',
      validTime: '',
      endTime: '',
      policyNo: '',
      policyType: undefined
    })
    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(customer.getCustomerPage, queryRef, true, res => res && res.length ? res.map(x => ({ ...x, drivingPic: x.drivingPic ? `${process.env.VUE_APP_IMG}${x.drivingPic}` : '' })) : [])

    function handleSearch () {
      resetPage()
      refresh()
    }

    async function handleReset () {
      formRef.value.resetFields()
      validTime.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      setPage(current)
    }

    const { exporting, exportFn } = useExport(() => customer.exportCustomer(queryRef.value))

    const editShow = ref(false)
    const currentCustomer = ref({})
    function handleAdd () {
      currentCustomer.value = {}
      editShow.value = true
    }
    function handleEdit (current) {
      currentCustomer.value = current
      editShow.value = true
    }

    function handleEditSuccess (isEdit) {
      if (isEdit) {
        refresh()
      } else {
        handleReset()
      }
    }

    const loading = ref(false)
    async function handleDelete (xindlCustomerId) {
      loading.value = true
      const { code, msg } = await customer.deleteCustomer(xindlCustomerId)
      loading.value = false
      if (code === '00000') {
        root.$message.success('删除成功')
        if (dataRef.value.length === 1) {
          handleSearch()
        } else {
          refresh()
        }
      } else {
        root.$message.error(msg || '删除失败，请稍后重试')
      }
    }

    const previewImage = ref('')

    return {
      columns,
      formRef,
      validTime,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      handleTableChange,
      exportLoading: exporting,
      handleExport: exportFn,
      editShow,
      currentCustomer,
      handleAdd,
      handleEdit,
      handleEditSuccess,
      loading,
      handleDelete,
      previewImage
    }
  }
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
